import HomePage from '../components/homePage';
import Layout from '../components/layout';
import { useAppContext } from '../contexts/appContext';
import SpaceportHome from './spaceportHome';

const Home = () => {
    const { user } = useAppContext();
    const isDemoUser =
        user?.groups && user.groups.filter(group => ['demo-users'].includes(group)).length > 0;
    const isSpaceportUser = !!user?.siberiaToken;

    return isSpaceportUser ? (
        <SpaceportHome />
    ) : (
        <Layout title="Fusion Home">
            <HomePage />
        </Layout>
    );
};

export default Home;
