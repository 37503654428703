import { ShoppingCart } from 'tabler-icons-react';
import Link from 'next/link';
import {
    Cylinder,
    FileCode,
    Globe,
    Graph,
    Handshake,
    LockKey,
    Package,
    Scroll,
    ShoppingBag,
    ShuffleAngular,
    Table,
    Tag,
    TrendUp,
    UserFocus,
} from 'phosphor-react';
import { Badge, Title } from '@mantine/core';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useUserAccess } from '../hooks/useUserAccess';
import { AllPagesType, OneBrainURL, PAGE_NAMES } from './navBar/constants';
import StyledTooltip from './utils/styledTooltip';
import { useAppContext } from '../contexts/appContext';

const subheader =
    "Query Arena's AI foundation model for consumer behavior. Understand how your products, stores & consumers interact across the market in real-time.";

const CustomLink = ({
    children,
    href,
    className,
    target = true,
    id,
}: {
    children: JSX.Element;
    href: string;
    className?: string;
    target?: boolean;
    id?: string;
}) => (
    <Link id={id} href={href} className={className} target={target ? '_blank' : undefined}>
        {children}
    </Link>
);

const HeroCard = ({
    icon,
    image,
    id,
    target = false,
    href = '',
    locked,
    isFusionLocked,
    badge,
    title,
}: {
    href?: AllPagesType | 'market-overview?viewTab=nielsen-sell-out' | '' | string;
    icon: JSX.Element;
    image: string;
    title?: string;
    id: string;
    target?: boolean;
    isFusionLocked?: boolean;
    locked?: boolean;
    badge?: 'BSI' | 'ABI';
}) => (
    <motion.div
        whileHover={{ scale: isFusionLocked || locked ? 1 : 1.02 }}
        transition={{ duration: 0.2 }}
        style={{
            zIndex: 1,
            width: '340px',
            height: '100px',
            backgroundColor: 'var(--secondary-bg)',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            position: 'relative',
            borderRadius: '0.5em',
            boxShadow: '8px 8px 16px rgba(10, 12, 15, 0.25)',
            backdropFilter: 'blur(8px)',
            border: '1px solid var(--secondary-bg)',
        }}
    >
        <CustomLink id={id} target={target} href={href}>
            <StyledTooltip disabled={!isFusionLocked} label="Login with a Fusion account">
                <div
                    style={{
                        borderRadius: '0.5em',
                        fontWeight: 'bold',
                        position: 'absolute',
                        width: '340px',
                        height: '100px',
                        paddingTop: '3.5em',
                        paddingLeft: '1em',
                        boxShadow: '8px 8px 16px rgba(10, 12, 15, 0.25)',
                        backdropFilter: 'blur(2px)',
                        cursor: isFusionLocked || locked ? 'not-allowed' : 'pointer',
                        backgroundImage:
                            'linear-gradient(to right, rgba(31,36,46,1) 20%, rgba(31,36,46,0.65) 50%, rgba(31,36,46,0.4) 60%, rgba(31,36,46,0) 100%)',
                    }}
                >
                    {(locked || isFusionLocked) && (
                        <LockKey
                            size={16}
                            weight="light"
                            color="white"
                            className="absolute left-[10px] top-[10px]"
                        />
                    )}
                    {badge && (
                        <Badge
                            color={badge === 'BSI' ? 'orange' : ''}
                            variant="filled"
                            className="absolute right-[10px] top-[10px]"
                        >
                            {badge}
                        </Badge>
                    )}
                    <Title
                        order={4}
                        className="flex flex-row gap-2 align-middle font-medium text-white"
                    >
                        {icon}
                        {PAGE_NAMES?.[href] || title}
                    </Title>
                </div>
            </StyledTooltip>
        </CustomLink>
    </motion.div>
);

const HomePage = () => {
    const { user } = useAppContext();
    const bennuAndArenaGroupsOnly = useUserAccess({
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
        userGroupBlacklist: 'force-remove-groups',
    });
    const isABIUser = useUserAccess({
        userGroupWhitelist: ['arena-staff', 'abi-users'],
        userGroupBlacklist: 'force-remove-groups',
    });

    return (
        <div className="flex w-full flex-col overflow-hidden pt-8">
            <div
                className="flex w-full flex-col"
                style={{
                    boxShadow: '0 8px 6px -6px black',
                }}
            >
                <div className="ml-4 flex flex-col gap-2 p-8">
                    <h1 className="text-2xl font-medium text-white">Welcome to Fusion</h1>
                    <span className="text-base font-medium text-navy-solid-30">{subheader}</span>
                </div>
                <div className="flex flex-row justify-around">
                    <div className={classNames('p-4 border-b-3 border-[#DF2960]')} role="link">
                        <Title order={4}>Data & Knowledge</Title>
                    </div>
                    <div className={classNames('p-4 border-b-3 border-[#47B6E5]')} role="link">
                        <Title order={4} className="">
                            Insights & Intelligence
                        </Title>
                    </div>
                </div>
            </div>

            <div className="flex w-full gap-2 pt-4">
                <div
                    key={1}
                    className="flex w-full flex-row flex-wrap items-start justify-center  gap-4"
                    style={{
                        minWidth: '340px',
                    }}
                >
                    <div className="grid w-min grid-flow-col grid-rows-4 flex-wrap items-center justify-start gap-4">
                        <HeroCard
                            image="images/integrations.png"
                            isFusionLocked={!user?.token}
                            title="Integrations"
                            icon={<ShuffleAngular size={24} weight="light" color="#DF2960" />}
                            id="home-integrations"
                            href="integrations"
                        />
                        <HeroCard
                            id="apis"
                            href={OneBrainURL}
                            image="images/apidocs.png"
                            icon={<FileCode size={24} weight="light" color="#DF2960" />}
                            title="APIs & Documentation"
                        />
                        {bennuAndArenaGroupsOnly && (
                            <HeroCard
                                id="entity-resolution"
                                isFusionLocked={!user?.token}
                                target={false}
                                href="entity-resolution"
                                image="images/datastitching.png"
                                icon={<Graph size={24} weight="light" color="#DF2960" />}
                            />
                        )}
                        <HeroCard
                            isFusionLocked={!user?.token}
                            id="home-prod-search"
                            href="productSearch"
                            image="images/products.png"
                            icon={<ShoppingCart size={24} strokeWidth={1} color="#DF2960" />}
                        />
                        <HeroCard
                            isFusionLocked={!user?.token}
                            id="home-consumers"
                            href="consumers"
                            image="images/consumers.png"
                            icon={<UserFocus size={24} weight="light" color="#DF2960" />}
                        />
                        <HeroCard
                            isFusionLocked={!user?.token}
                            id="home-stores"
                            image="images/stores.png"
                            href="context-module"
                            title="Stores"
                            icon={<ShoppingBag size={24} weight="light" color="#DF2960" />}
                        />

                        {isABIUser && (
                            <HeroCard
                                id="home-pricing-intelligence"
                                isFusionLocked={!user?.token}
                                target={false}
                                href="pricing-intelligence"
                                image="images/pricing.png"
                                icon={
                                    <Package
                                        size={24}
                                        strokeWidth={1}
                                        weight="thin"
                                        color="#DF2960"
                                    />
                                }
                                badge="ABI"
                            />
                        )}
                    </div>
                </div>

                <div
                    key={2}
                    className="flex w-full flex-row flex-wrap items-start justify-center  gap-4"
                    style={{
                        minWidth: '340px',
                    }}
                >
                    <div className="grid w-min grid-flow-col grid-rows-4 flex-wrap items-center justify-start gap-4">
                        {bennuAndArenaGroupsOnly && (
                            <HeroCard
                                isFusionLocked={!user?.token}
                                id="assortment-optimization"
                                href="assortment-optimization"
                                image="images/ontrade.png"
                                icon={<Handshake size={24} weight="light" color="#47b6e5" />}
                            />
                        )}
                        <HeroCard
                            isFusionLocked={!user?.token}
                            id="promotion-effectiveness"
                            image="images/promotion_ROI_Simulator.png"
                            title="Promotion ROI & Simulator"
                            icon={<Tag size={24} weight="light" color="#47b6e5" />}
                            locked
                        />
                        <HeroCard
                            isFusionLocked={!user?.token}
                            id="market-performance"
                            href="market-performance"
                            image="images/market_Performance_Overview.png"
                            icon={<TrendUp size={24} weight="light" color="#47b6e5" />}
                        />
                        <HeroCard
                            isFusionLocked={!user?.token}
                            id="supply-chain-forecast"
                            image="images/supply_Chain_Forecasting.png"
                            title="Supply Chain & Forecasting"
                            icon={<Globe size={24} weight="light" color="#47b6e5" />}
                            locked
                        />
                        {bennuAndArenaGroupsOnly && (
                            <>
                                <HeroCard
                                    isFusionLocked={!user?.token}
                                    id="home-market-overview"
                                    href="market-overview"
                                    image="images/deepdive.png"
                                    icon={<Table size={24} weight="light" color="#47b6e5" />}
                                    badge="BSI"
                                />
                                <HeroCard
                                    isFusionLocked={!user?.token}
                                    id="home-executive-summary"
                                    href="market-recap"
                                    image="images/nielsen_market_recap.png"
                                    icon={<Scroll size={24} weight="light" color="#47b6e5" />}
                                />
                                <HeroCard
                                    isFusionLocked={!user?.token}
                                    id="seasonality"
                                    href="market-overview?viewTab=nielsen-sell-out"
                                    image="images/seasons.png"
                                    title="Seasonal Dynamics"
                                    icon={
                                        <Cylinder
                                            size={24}
                                            strokeWidth={1}
                                            weight="thin"
                                            color="#47b6e5"
                                        />
                                    }
                                    badge="BSI"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
