/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Head from 'next/head';
import Link from 'next/link';
import { Flask, Handshake, LockSimple, ShuffleAngular, TrendUp } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

type AllPagesType = 'integrations' | 'productSearch' | 'consumers' | '/';

const Container = ({ children, twoRows }: { children: JSX.Element[]; twoRows: boolean }) => (
    <div
        className={classNames('grid grid-cols-3 pt-4 grid-flow-row mt-8 gap-8', {
            '!grid-cols-2': !twoRows,
        })}
    >
        {children}
    </div>
);

const CustomLink = ({
    children,
    href,
    className,
    target = true,
    id,
}: {
    children: JSX.Element;
    href: string;
    className?: string;
    target?: boolean;
    id?: string;
}) => (
    <Link id={id} href={href} className={className} target={target ? '_blank' : undefined}>
        {children}
    </Link>
);

const SubContainer = ({
    children,
    href,
    target = true,
    disabled,
    className,
    id,
}: {
    children: JSX.Element[];
    href?: AllPagesType;
    target?: boolean;
    disabled?: boolean;
    className?: string;
    id?: string;
}) => {
    const styling = 'flex w-[272px] items-start flex-col rounded-lg gap-1 px-6 pb-6 bg-[#1a1f26]';
    if (disabled) return <div className={`${styling} opacity-50`}>{children}</div>;
    return (
        <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
            id={id}
            className={`${styling} border-1 border-secondary-bg transition-colors ${className}`}
        >
            {children}
        </motion.div>
    );
};

const CardIconContainer = ({
    children,
    displayAccessMessage,
}: {
    children: JSX.Element;
    displayAccessMessage?: boolean;
}) => {
    const iconStyling = 'rounded-full bg-primary p-6 -mt-4 hover:border-sky-600 transition-colors';
    if (displayAccessMessage)
        return (
            <div className="flex w-full justify-between">
                <div className={iconStyling}>{children}</div>
                <div className="-mr-4 mt-2.5 flex flex-col items-end">
                    <LockSimple size={24} className="my-1" />
                    <span className="hover:text-[10px]">INQUIRE FOR ACCESS</span>
                </div>
            </div>
        );
    return <div className={iconStyling}>{children}</div>;
};

const CardTitleContainer = ({ children }: { children: string }) => (
    <h2 className="mt-5 justify-center text-left text-lg">{children}</h2>
);

const CardDescriptionContainer = ({
    children,
    href,
    target,
    color,
}: {
    children: JSX.Element[];
    href;
    target;
    color;
}) => (
    <span className="w-[224px] text-sm text-gray-400">
        {children.map((item, idx) => (
            <CustomLink
                key={idx}
                href={href[idx] !== '/' ? `/${href[idx]}` : href[idx]}
                target={target}
                className="transition-colors hover:text-sky-600"
            >
                {item}
            </CustomLink>
        ))}
    </span>
);

const SpaceportHomePage = () => {
    const { t } = useTranslation();

    return (
        <div className="flex h-dvh w-full min-w-[700px] items-center justify-center self-center shadow-lg shadow-black">
            <Head>
                <title>{t('spaceportHome.headTitle')}</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            <div className="max-h-[95vh] overscroll-auto lg:max-h-[96vh] ">
                <div className="glass-effect z-20 mb-8 flex flex-col gap-5 rounded-3xl border-transparent p-8">
                    <div className="flex flex-col">
                        <h1 className="text-3xl font-light">{t('spaceportHome.pageTitle')}</h1>
                    </div>
                    <Container twoRows={false}>
                        <SubContainer
                            id="home-prod-search"
                            target={false}
                            href="productSearch"
                            className="hover:border-[#C839DF] hover:shadow-md hover:shadow-[#C839DF]"
                        >
                            <CardIconContainer>
                                <TrendUp size={40} strokeWidth={1} color="#C839DF" />
                            </CardIconContainer>
                            <CardTitleContainer>
                                {t('spaceportHome.revenueAlgoSales.cardTitle')}
                            </CardTitleContainer>
                            <CardDescriptionContainer
                                href={['strategy', 'commercial-impact', 'promo-manager']}
                                target={false}
                                color="#C839DF"
                            >
                                <div className="pb-1 font-bold hover:text-[#C839DF]">
                                    - {t('spaceportHome.revenueAlgoSales.setBusinessStrategy')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#C839DF]">
                                    -{' '}
                                    {t('spaceportHome.revenueAlgoSales.observeTheCommercialImpact')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#C839DF]">
                                    - {t('spaceportHome.revenueAlgoSales.promoManagement')}
                                </div>
                            </CardDescriptionContainer>
                        </SubContainer>
                        <SubContainer
                            id="home-consumers"
                            target={false}
                            href="consumers"
                            className="hover:border-[#EBB938] hover:shadow-md hover:shadow-[#EBB938]"
                        >
                            <CardIconContainer>
                                <Handshake size={40} weight="light" color="#EBB938" />
                            </CardIconContainer>
                            <CardTitleContainer>
                                {t('spaceportHome.productManager.cardTitle')}
                            </CardTitleContainer>
                            <CardDescriptionContainer
                                href={['results', 'configurations', 'personalizations']}
                                target={false}
                                color="#EBB938"
                            >
                                <div className="pb-1 font-bold hover:text-[#EBB938]">
                                    - {t('spaceportHome.productManager.checkHowAIIsPerforming')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#EBB938]">
                                    - {t('spaceportHome.productManager.configureMultiModalAI')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#EBB938]">
                                    - {t('spaceportHome.productManager.drillDownToStoreLevelView')}
                                </div>
                            </CardDescriptionContainer>
                        </SubContainer>
                        <SubContainer
                            id="home-integrations"
                            target={false}
                            href="integrations"
                            className="hover:border-[#DF2960] hover:shadow-md hover:shadow-[#DF2960]"
                        >
                            <CardIconContainer>
                                <Flask size={40} weight="light" color="#DF2960" />
                            </CardIconContainer>
                            <CardTitleContainer>
                                {t('spaceportHome.AITeam.cardTitle')}
                            </CardTitleContainer>
                            <CardDescriptionContainer
                                href={['console', 'datasets']}
                                target={false}
                                color="#DF2960"
                            >
                                <div className="pb-1 font-bold hover:text-[#DF2960]">
                                    - {t('common.spaceportCommandControl')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#DF2960]">
                                    - {t('common.uploadDatasets')}
                                </div>
                            </CardDescriptionContainer>
                        </SubContainer>
                        <SubContainer
                            id="home-integrations"
                            target={false}
                            href="integrations"
                            className="hover:border-[#59d8de] hover:shadow-md hover:shadow-[#59d8de]"
                        >
                            <CardIconContainer>
                                <ShuffleAngular size={40} weight="light" color="#59D8DE" />
                            </CardIconContainer>
                            <CardTitleContainer>
                                {t('spaceportHome.engineeringTeam.cardTitle')}
                            </CardTitleContainer>
                            <CardDescriptionContainer
                                href={['console', 'datasets', 'personalizations']}
                                target={false}
                                color="#59D8DE"
                            >
                                <div className="pb-1 font-bold hover:text-[#59D8DE]">
                                    - {t('common.spaceportCommandControl')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#59D8DE]">
                                    - {t('common.uploadDatasets')}
                                </div>
                                <div className="pb-1 font-bold hover:text-[#59D8DE]">
                                    -{' '}
                                    {t(
                                        'spaceportHome.engineeringTeam.integrateWithThePersonalizationsApi',
                                    )}
                                </div>
                            </CardDescriptionContainer>
                        </SubContainer>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default SpaceportHomePage;
